// Login.jsx
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface LoginScreenProps extends RouteComponentProps<any> {}

const LoginScreenInner = ({ history }: LoginScreenProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (event: any) => {
    event.preventDefault();
    const data = { username, password };

    fetch('/login', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          history.push('/home');
        } else {
          console.log('Login error');
          const error = new Error(res.statusText);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Error logging in please try again');
      });
  };

  return (
    <div className="container">
      <form onSubmit={onSubmit}>
        <div className="row margin-top-large">
          <div className="md-col-3 md-offset-3">
            <h1>QuickSWPPP Login</h1>
          </div>
        </div>
        <div className="row margin-top-large">
          <div className="md-col-3 md-offset-3">
            <input
              type="text"
              name="username"
              placeholder="Enter UserName"
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
              required
            />
          </div>
        </div>
        <div className="row margin-top-large">
          <div className="md-col-3 md-offset-3">
            <input
              type="password"
              name="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              required
            />
          </div>
        </div>
        <div className="row margin-top-large">
          <div className="md-col-3 md-offset-3">
            <input type="submit" value="Login!" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default withRouter(LoginScreenInner);
