import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { pdfjs, Document, Page } from 'react-pdf';
import {
  QSPFormData,
  QspWeatherData,
  StormObservationAtLocation,
  BMP,
  BMPRecommendation,
  BMPCommentRecommendation,
  EmailInfo,
} from './bmps';
import { today, averageOfNonZero, resizeImage, parseProjectEmailString } from './utils';
import download from 'downloadjs';
import moment from 'moment';
import dateformat from 'dateformat';
import { Project, LoggedInUser } from './data';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { QuickSWPPPLogo } from './components';
import ImageUploader from 'react-images-upload';
import ReactDOMServer from 'react-dom/server';
import ContentEditable from 'react-contenteditable';

// Load the PDF worker from a CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Get the recommendation text for a BMP recommendation, depending on whether it was previously recommended
// and whether it is recommended now.
function getRecommendationText(
  isChecked: boolean,
  prevRecommendation?: BMPRecommendation | BMPCommentRecommendation
): [string, string] {
  let recommendationText = '';
  let recommendationDate = '';

  const prevRecommendedOnDate = prevRecommendation?.recommendedOnDate;

  if (prevRecommendedOnDate && isChecked) {
    // Continue to recommend, so say
    recommendationDate = prevRecommendedOnDate;
    recommendationText = `Previously recommended on ${prevRecommendedOnDate}`;
  } else if (prevRecommendedOnDate && !isChecked) {
    // Was fixed
    recommendationDate = '';
    recommendationText = `Action taken on ${dateformat(today(), 'mm-dd-yyyy')}`;
  } else if (!prevRecommendedOnDate && isChecked) {
    // Newly recommended
    recommendationDate = dateformat(today(), 'mm-dd-yyyy');
    recommendationText = `Recommended on ${dateformat(today(), 'mm-dd-yyyy')}`;
  }

  return [recommendationDate, recommendationText];
}

type BMPSelectorProps = {
  bmp: BMP;
  prevBMP?: BMP;
  currentBMPRecommendations: Set<String>;
  bmpComment: BMPCommentRecommendation;
  setPictures: (bmpID: string, pics: File[]) => void;
  toggleBMPRecommendation: (combinedID: string, isChecked: boolean) => void;
  setBMPComment: (bmpID: string, has_comment: boolean, comment: string | undefined) => void;
};

export const BMPSelector = ({
  bmp,
  prevBMP,
  currentBMPRecommendations,
  bmpComment,
  setPictures,
  toggleBMPRecommendation,
  setBMPComment,
}: BMPSelectorProps) => {
  const onDrop = async (pics: File[]) => {
    setPictures(bmp.id, pics);
  };

  const includeHeader = bmp.id.endsWith('1');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_dt, commentRecommendationText] = getRecommendationText(bmpComment.has_comment, prevBMP?.comment);

  const isBMPRecommended =
    bmp.recommendations.filter((r) => currentBMPRecommendations.has(`${bmp.id}-${r.id}`)).length > 0 ||
    bmpComment.has_comment;

  return (
    <div>
      <div>
        {includeHeader && (
          <div style={{ marginTop: '10px', backgroundColor: '#aaaaaa', padding: '5px' }}>
            <h3>
              {bmp.section.id.split('-')[0]} - {bmp.section.name}
            </h3>
          </div>
        )}
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-md-8">
            <strong>
              {bmp.id} - {bmp.name}
            </strong>
          </div>
          <div className="col-md-2">{isBMPRecommended ? 'NO' : 'YES'}</div>
        </div>
        <div>
          {bmp.recommendations.map((r) => {
            const combinedID = `${bmp.id}-${r.id}`;
            const isChecked = currentBMPRecommendations.has(combinedID);

            const prevRecommended = prevBMP?.recommendations.find((pr) => pr.id === r.id);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_recommendationDate, recommendationText] = getRecommendationText(isChecked, prevRecommended);

            return (
              <div key={combinedID} className="row">
                <div className="col-md-8">{r.name || '<Check to apply BMP>'}</div>
                <div className="col-md-2">
                  <input
                    type="checkbox"
                    id={combinedID}
                    checked={isChecked}
                    onChange={(e) => {
                      toggleBMPRecommendation(combinedID, e.currentTarget.checked);
                    }}
                  />
                </div>
                <div className="col-md-2">{recommendationText}</div>
              </div>
            );
          })}
        </div>
        <br />
        Comment:
        <br />
        <div className="row">
          <div className="col-md-8">
            <textarea
              style={{ width: '100%' }}
              name={bmp.id + 'comment'}
              disabled={!bmpComment.has_comment}
              value={bmpComment.comment}
              onChange={(e) => {
                setBMPComment(bmp.id, bmpComment.has_comment, e.currentTarget.value);
              }}
            ></textarea>
          </div>
          <div className="col-md-2">
            <input
              type="checkbox"
              id={bmp.id}
              checked={bmpComment.has_comment}
              onChange={(e) => {
                setBMPComment(bmp.id, e.currentTarget.checked, bmpComment.comment);
              }}
            />
          </div>
          <div className="col-md-2">{commentRecommendationText}</div>
        </div>
        <ImageUploader
          withPreview={true}
          withIcon={false}
          label={''}
          buttonText="Choose images"
          onChange={onDrop}
          imgExtension={['.jpg', '.jpeg']}
          maxFileSize={5242880}
        />
      </div>
    </div>
  );
};

type StormWaterObservationInputProps = {
  site?: Project;
  index: number;
  stormwaterobservation: StormObservationAtLocation;
  updateStormWaterObservation: (index: number, observation: StormObservationAtLocation | null) => void;
};
export const StormWaterObservationInput = ({
  site,
  index,
  stormwaterobservation,
  updateStormWaterObservation,
}: StormWaterObservationInputProps) => {
  const turbidity_observations = [
    'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION',
    'STORMWATER DISCHARGE OBSERVED HAD HIGH TURBIDITY',
    'STORMWATER DISCHARGE OBSERVED HAD LOW TURBIDITY',
    'NO EXCEPTION TAKEN TO STORMWATER DISCHARGE',
    'STORMWATER DISCHARGE HAD SHEEN, DISCOLORATION OR SUSPENDED MATERIALS',
  ];

  const ph_observations = [
    '',
    'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION',
    'STORMWATER DISCHARGE MEASUREMENT HAD HIGH pH',
    'STORMWATER DISCHARGE MEASUREMENT HAD LOW pH',
    'NO EXCEPTION TAKEN TO STORMWATER DISCHARGE',
  ];

  const updateLocation = (location: string) => {
    let newobservation = Object.assign({}, stormwaterobservation);
    newobservation.location = location;

    updateStormWaterObservation(index, newobservation);
  };

  const updatePh = (ph: string) => {
    let newobservation = Object.assign({}, stormwaterobservation);
    newobservation.ph = ph;

    updateStormWaterObservation(index, newobservation);
  };

  const updatePhObservation = (observation: string) => {
    let newobservation = Object.assign({}, stormwaterobservation);
    newobservation.ph_observation = observation;

    updateStormWaterObservation(index, newobservation);
  };

  const updateTurbidity = (turbidity: string) => {
    let newobservation = Object.assign({}, stormwaterobservation);
    newobservation.turbidity = turbidity;

    updateStormWaterObservation(index, newobservation);
  };

  const updateTurbidityObservation = (observation: string) => {
    let newobservation = Object.assign({}, stormwaterobservation);
    newobservation.turbidity_observation = observation;

    updateStormWaterObservation(index, newobservation);
  };

  const updateComment = (comment: string) => {
    let newobservation = Object.assign({}, stormwaterobservation);
    newobservation.comment = comment;

    updateStormWaterObservation(index, newobservation);
  };

  return (
    <tr key={index}>
      <td>
        Location:
        <input
          type="text"
          value={stormwaterobservation.location}
          onChange={(e) => updateLocation(e.currentTarget.value)}
        />
        <br />
        <button type="button" className="btn btn-danger" onClick={(e) => updateStormWaterObservation(index, null)}>
          Delete Location
        </button>
      </td>

      <td>
        <table>
          <tbody>
            {site?.risklevel_type !== '1' && (
              <tr>
                <td>pH: (multiple values separated by commas)</td>
                <td>
                  <input
                    type="text"
                    value={stormwaterobservation.ph || ''}
                    onChange={(e) => updatePh(e.currentTarget.value)}
                  />
                </td>
              </tr>
            )}

            {site?.risklevel_type !== '1' && (
              <tr>
                <td>Turbidity: (multiple values separated by commas)</td>
                <td>
                  <input
                    type="text"
                    value={stormwaterobservation.turbidity || ''}
                    onChange={(e) => updateTurbidity(e.currentTarget.value)}
                  />
                </td>
              </tr>
            )}

            {site?.risklevel_type === '1' && (
              <tr>
                <td>Turbidity Observation:</td>
                <td>
                  <select
                    name="observations"
                    style={{ width: '400px' }}
                    onChange={(e) => updateTurbidityObservation(e.currentTarget.value)}
                  >
                    {turbidity_observations.map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            )}
            <tr>
              <td>Additional Observation Comments:</td>
              <td>
                <textarea style={{ width: '400px' }} onChange={(e) => updateComment(e.currentTarget.value)} />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

// Get an array with a flat list of recommendations from the given QSP data. Useful for adding it to sent emails.
const getBMPRecommendations = (qspForm: QSPFormData | null): string[] => {
  if (!qspForm) return [];
  var bmplist = qspForm.bmps.flatMap((b) =>
    b.recommendations
      .map((r) => r.name)
      .concat(b.comment && b.comment.has_comment && b.comment.comment ? [b.comment.comment] : [])
  );

  return bmplist;
};

type EmailBodyProps = {
  generatedQspForm: QSPFormData | null;
  user: LoggedInUser;
};
const EmailBody = ({ generatedQspForm, user }: EmailBodyProps) => {
  return (
    <div>
      <p>Hello,</p>
      <p>
        See attached for the {generatedQspForm?.inspectiontype} QSP Inspection Report on {generatedQspForm?.datetime}.{' '}
      </p>
      {getBMPRecommendations(generatedQspForm).length > 0 && (
        <>
          <p>This report contains recommended SWPPP action items</p>
          {getBMPRecommendations(generatedQspForm).map((s, i) => (
            <p key={i} style={{ color: 'red' }}>
              {i + 1}. {s}
            </p>
          ))}
        </>
      )}
      <p>Please let me know if you have any questions.</p>
      <br />
      <p> - {generatedQspForm?.inspectorname} </p>
      <br />
      <pre>{user.emailSignature}</pre>
    </div>
  );
};

interface QspScreenProps extends RouteComponentProps<any> {
  projects: Project[];
  user: LoggedInUser;
}
const QspScreen = ({ projects, user, history }: QspScreenProps) => {
  let allInspectionTypes = ['Weekly', 'Bi-Weekly', 'Monthly', 'Pre-Storm', 'Storm Event', 'Post-Storm'];

  let [currentBMPs, setCurrentBMPs] = useState(new Set<string>());
  let [bmpComments, setBMPComments] = useState(new Map<string, BMPCommentRecommendation>());
  let [exemption, setExemption] = useState('');
  let [allbmps, setAllBMPs] = useState(new Array<BMP>());
  let [uploadedPictures, setUploadedPictures] = useState(new Map<string, File[]>());

  const defaultSite = projects && projects[0] ? projects[0] : undefined;

  let [site, setSite] = useState(defaultSite);
  let [weather, setWeather] = useState(new QspWeatherData());
  let [inspectiontypes, setInspectionTypes] = useState(
    new Set<string>([allInspectionTypes[0]])
  );
  let [qspDate, setQspDate] = useState(moment(today()).format('MM-DD-YYYY'));
  const emailBody = useRef('');

  let [prevQspForm, setPrevQspForm] = useState<QSPFormData | null>(null);

  let [stormwaterobservations, setStormWaterObservations] = useState(new Array<StormObservationAtLocation>());

  let [screenNumber, setScreenNumber] = useState(0);
  let [pdffile, setPdffile] = useState<string | null>(null);
  let [generatedQspForm, setGeneratedQspForm] = useState<QSPFormData | null>(null);
  let [pdfNumPages, setPdfNumPages] = useState<number | null>(null);
  let [emailResult, setEmailResult] = useState('');

  // Fetch all BMPs from the server everytime we load
  useEffect(() => {
    fetch('/allbmps')
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log('Error');
          console.log(res);
          history.push('/');
        }
      })
      .then((j) => setAllBMPs(j));
  }, [history]);

  // Set the weather for the default site
  useEffect(() => {
    updateSiteName(defaultSite?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSite]);

  if (!projects || projects.length === 0) {
    history.push('/');
  }

  const toggleBMPRecommendation = (combinedID: string, isChecked: boolean) => {
    const newCurrentBMPs = new Set(currentBMPs);
    if (isChecked) {
      newCurrentBMPs.add(combinedID);
    } else {
      newCurrentBMPs.delete(combinedID);
    }

    setCurrentBMPs(newCurrentBMPs);
  };

  const setPictures = (bmpId: string, pictures: File[]) => {
    let newUploadedPictuers = new Map<string, File[]>(uploadedPictures);
    newUploadedPictuers.set(bmpId, pictures);

    setUploadedPictures(newUploadedPictuers);
  };

  const toggleInspectionType = (id: string, isChecked: boolean) => {
    const newInspectionTypes = new Set(inspectiontypes);
    if (isChecked) {
      newInspectionTypes.add(id);
    } else {
      newInspectionTypes.delete(id);
    }

    setInspectionTypes(newInspectionTypes);
  };

  const setBMPComment = (bmpID: string, has_comment: boolean, comment: string | undefined) => {
    const newBMPComments = new Map<string, BMPCommentRecommendation>(bmpComments);
    const commnetObject = new BMPCommentRecommendation(has_comment, comment);
    newBMPComments.set(bmpID, commnetObject);

    setBMPComments(newBMPComments);
  };

  const updatePrevQspForm = (prev_qsp: QSPFormData) => {
    // Don't update if there wasn't any prev form
    if (!prev_qsp || !prev_qsp.sitename) {
      return;
    }

    setPrevQspForm(prev_qsp);

    // Get all the locations
    const locations = prev_qsp.stormobservationsatlocation.filter((o) => o.location).map((o) => o.location);
    const new_obvs = locations
      .filter((l) => !l.startsWith('(Entire Site Average)'))
      .map(
        (l) =>
          new StormObservationAtLocation(
            l,
            null,
            '',
            null,
            'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION',
            null
          )
      );
    if (new_obvs.length > 0) {
      setStormWaterObservations(new_obvs);
    } else {
      setStormWaterObservations([
        new StormObservationAtLocation(
          '',
          null,
          '',
          null,
          'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION',
          null
        ),
      ]);
    }

    // Copy over all the previous BMP recommendations
    const prevBMPs = prev_qsp.bmps.flatMap((b) =>
      b.recommendations.map((r) => {
        const combinedID = `${b.id}-${r.id}`;
        return combinedID;
      })
    );
    setCurrentBMPs(new Set(prevBMPs));

    // Copy over all the BMP Comments
    const newBMPComments = new Map<string, BMPCommentRecommendation>();
    prev_qsp.bmps.forEach((b) => {
      if (b.comment && b.comment.has_comment) {
        newBMPComments.set(b.id, b.comment);
      }
    });
    
    setBMPComments(newBMPComments);
  };

  const updateSiteName = async (sitename?: string) => {
    // Get the site's weather data
    const zipcode = projects.find((p) => p.name === sitename)?.zipcode || '0';

    const weather_res = await fetch(`/last_rain_event?zipcode=${zipcode}`);
    if (weather_res.status === 200) {
      const j = await weather_res.json();
      setWeather(j);
    }

    // Also, get the site's last qsp form
    const qsp_res = await fetch(`/last_qsp_form?sitename=${sitename}`);
    const status = qsp_res.status;
    if (status === 200) {
      const j = await qsp_res.json();
      updatePrevQspForm(j);
    } else if (status === 404) {
      console.log('404, setting empty observations');
      // Create an empty storm water observations if there wasn't a previous site
      setStormWaterObservations([
        new StormObservationAtLocation(
          '',
          null,
          '',
          null,
          'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION',
          null
        ),
      ]);

      // Clear the BMPs if the site name changes
      setCurrentBMPs(new Set());
    }

    const site = projects.find((p) => p.name === sitename);
    setSite(site);
  };

  const clearQspForm = () => {
    emailBody.current = '';
    setUploadedPictures(new Map());
    setGeneratedQspForm(null);
    setPdffile(null);
    setScreenNumber(0);
  };

  const genBMPsFromCurrent = async () => {
    const newBMPs = await Promise.all(
      allbmps.map(async (bmp) => {
        // Process Recommendations
        const recommendations = bmp.recommendations
          .filter((r) => {
            const combinedID = `${bmp.id}-${r.id}`;
            return currentBMPs.has(combinedID);
          })
          .map((r) => {
            const [recommendationDate, recommendationText] = getRecommendationText(
              true,
              prevQspForm?.bmps.find((b) => b.id === bmp.id)?.recommendations.find((pr) => pr.id === r.id)
            );
            return new BMPRecommendation(r.id, r.name, recommendationDate, recommendationText);
          });

        // Process Comment
        const bmp_comment = bmpComments.get(bmp.id) || new BMPCommentRecommendation(false);
        const [commentRecommendationDate, commentRecommendationText] = getRecommendationText(
          bmp_comment.has_comment,
          prevQspForm?.bmps.find((b) => b.id === bmp.id)?.comment
        );
        if (commentRecommendationText) {
          bmp_comment.recommendationText = commentRecommendationText;
          bmp_comment.recommendedOnDate = commentRecommendationDate;
        }

        // Pick the first recommendation as the overall text
        let recommendationText: string | undefined;
        const recommendationTexts = recommendations
          .filter((r) => r.recommendationText)
          .map((r) => r.recommendationText);
        if (recommendationTexts && recommendationTexts.length > 0) {
          recommendationText = recommendationTexts[0];
        } else {
          if (bmp_comment.recommendationText) {
            recommendationText = bmp_comment.recommendationText;
          }
        }

        // If there is no recommendation, see if any previous recommendations got fixed
        if (recommendations.length === 0 && !recommendationText) {
          recommendationText = bmp.recommendations
            .map((r) => {
              const [_, recommendationText] = getRecommendationText(
                false,
                prevQspForm?.bmps.find((b) => b.id === bmp.id)?.recommendations.find((pr) => pr.id === r.id)
              );
              return recommendationText;
            })
            .find((rt) => rt);
        }

        // Grab any pictures, and turn them into base64
        const pics = uploadedPictures.get(bmp.id);
        var picsBase64: string[] = [];
        if (pics && pics.length && pics.length > 0) {
          picsBase64 = await Promise.all(
            pics.map(async (pic: File) => {
              const blob: any = await resizeImage({ file: pic, maxSize: 575 });
              const arrayBuffer = await blob.arrayBuffer();

              return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            })
          );
        }

        return new BMP(
          bmp.id,
          bmp.section.id,
          bmp.name,
          recommendations,
          bmp_comment,
          recommendationText || '',
          picsBase64
        );
      })
    );

    return newBMPs;
  };

  const processStormWaterObservationsForReport = (): StormObservationAtLocation[] => {
    const risklevel = (site?.risklevel_type || '').trim();
    const inspectiontype = Array.from(inspectiontypes.values()).join(', ');
    const processedStormWaterObservations = [];

    let anyComment = false;
    let anyPhOrTurbidityValue = false;

    // Process the storm water observations if this is a storm event.
    if (inspectiontype.toLowerCase().includes('storm event')) {
      // 1. Process the individual storm water observations
      stormwaterobservations.forEach((so) => {
        var [to, tv, po, pv]: string[] = ['', '', '', ''];

        if (risklevel === '1' && so.turbidity_observation) {
          to = so.turbidity_observation;
          tv = '';
        }

        if (risklevel === '2' && so.turbidity) {
          tv = `Turbidity: ${so.turbidity} NTUs`;
          to = '';
        }

        if (so.ph) {
          pv = `pH Values: ${so.ph}`;
        }

        if (to || tv || pv) {
          anyPhOrTurbidityValue = true;
        }
        if (so.comment) {
          anyComment = true;
        }

        // Add if there is some ph or turbidity value or observation
        processedStormWaterObservations.push(
          new StormObservationAtLocation(so.location, pv, po, tv, to, so.comment || '')
        );
      });

      // 2. Calculate the averages if required
      if (risklevel === '2' && (anyPhOrTurbidityValue || !anyComment)) {
        // Aggregate the turbidity and ph averages
        const ph_average = averageOfNonZero(stormwaterobservations.map((so) => so.ph));
        const turbidity_average = averageOfNonZero(stormwaterobservations.map((so) => so.turbidity));

        const averages: any = {};
        if (ph_average) {
          averages.ph_average = `pH Value: ${ph_average}`;
          averages.ph_observation =
            ph_average < 6.5
              ? 'STORMWATER DISCHARGE MEASUREMENT HAD LOW pH'
              : ph_average < 8.5
              ? 'NO EXECEPTION TAKEN TO pH MEASUREMENT OF STORMWATER DISCHARGE'
              : 'STORMATER DISCHARGE MEASUREMENT HAD HIGH pH';
        } else {
          // Check to see if we should insert the default observation if there were no ph values. This is true only because
          // we already know this site is risklevel === 2
          averages.ph_average = '';
          averages.ph_observation =
            'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION, THEREFORE pH AND TURBIDITY MEASUREMENTS WERE NOT ABLE TO BE RECORDED';
        }

        if (turbidity_average) {
          averages.turbidity_average = `Turbidity: ${turbidity_average} NTUs`;
          averages.turbidity_observation =
            turbidity_average < 250
              ? 'NO EXCEPTION TAKEN TO STORMWATER DISCHARGE TURBIDITY MEASUREMENT'
              : 'STORMWATER DISCHARGE MEASURED HAD HIGH TURBIDITY';
        }

        if (averages.ph_average || averages.ph_observation || averages.turbidity_average) {
          // Add the "Site average site."
          const averageObservation = new StormObservationAtLocation(
            '(Entire Site Average)',
            averages.ph_average,
            averages.ph_observation,
            averages.turbidity_average,
            averages.turbidity_observation,
            ''
          );
          processedStormWaterObservations.unshift(averageObservation);
        }
      }
    }

    return processedStormWaterObservations;
  };

  const getQspFormData = async (): Promise<QSPFormData> => {
    const bmps = await genBMPsFromCurrent();

    if (!site) {
      console.log('No site to generate form');
      throw Error('No site');
    }

    let exemption_reason = exemption;
    if (!exemption_reason) {
      exemption_reason = '';
    }

    const inspectiontype = Array.from(inspectiontypes.values()).join(', ');
    const processedStormWaterObservations = processStormWaterObservationsForReport();

    const photosTaken = bmps.filter((b) => b.picturesBase64 && b.picturesBase64.length > 0).length > 0;

    return new QSPFormData(
      qspDate,
      inspectiontype,
      site.name || '',
      site.risklevel_type || '',
      photosTaken,
      exemption_reason,
      weather,
      user.displayname || '',
      bmps,
      processedStormWaterObservations
    );
  };

  const genQSPForm = async () => {
    const qspFormData = await getQspFormData();
    fetch('/qsp_form', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(qspFormData),
    })
      .then((res) => res.blob())
      .then(async (blob: any) => {
        const arrayBuffer = await blob.arrayBuffer();

        const data = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

        emailBody.current = getDefaultEmailBody(qspFormData);
        setGeneratedQspForm(qspFormData);
        setPdffile(data);
        setScreenNumber(1);
      });
  };

  const getEmailTo = (): string[] => {
    return parseProjectEmailString(site?.email, user);
  };

  const getEmailSubjet = (): string => {
    return `${generatedQspForm?.sitename} - ${generatedQspForm?.inspectiontype} QSP Inspection Report`;
  };

  const getDefaultEmailBody = (qspFromData: QSPFormData): string => {
    return ReactDOMServer.renderToStaticMarkup(<EmailBody generatedQspForm={qspFromData} user={user} />);
  };

  const sendEmail = async () => {
    const qspdata = await getQspFormData();
    const emailinfo = new EmailInfo(
      user?.email || '',
      getEmailTo(),
      getEmailSubjet(),
      emailBody.current,
      `${qspdata.datetime}-${qspdata.sitename}-qspreport.pdf`
    );

    const postBody = { qspdata, emailinfo };

    fetch('/send_email', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postBody),
    })
      .then((res) => res.json())
      .then((r) => {
        console.log(`Send email result ${JSON.stringify(r)}`);
        setEmailResult(r.status);
        setScreenNumber(2);
      });
  };

  const updateWeather = (begin?: string, duration?: string, last?: string, rainGuage?: string) => {
    if (typeof begin === 'undefined') {
      begin = weather.stormBegining;
    }

    if (typeof duration === 'undefined') {
      duration = weather.stormDuration;
    }

    if (typeof last === 'undefined') {
      last = weather.timeSinceLastStorm;
    }

    if (typeof rainGuage === 'undefined') {
      rainGuage = weather.rainGuage;
    }

    const newWeather = new QspWeatherData(begin, duration, last, rainGuage);
    setWeather(newWeather);
  };

  const updateStormWaterObservation = (index: number, swo: StormObservationAtLocation | null): void => {
    let newStormWaterObservations;

    // If swo is null, that means to remove this location
    if (!swo) {
      newStormWaterObservations = stormwaterobservations.filter((o, idx) => idx !== index);
      setStormWaterObservations(newStormWaterObservations);
    } else {
      // Deep copy
      newStormWaterObservations = JSON.parse(JSON.stringify(stormwaterobservations));
      newStormWaterObservations[index] = swo;
    }

    setStormWaterObservations(newStormWaterObservations);
  };

  const addEmptyStormWaterObservation = (location?: string) => {
    let newStormWaterObservations = JSON.parse(JSON.stringify(stormwaterobservations));
    newStormWaterObservations.push(
      new StormObservationAtLocation(
        location || '',
        null,
        '',
        null,
        'NO OBSERVED STORMWATER DISCHARGE DURING QSP SITE INSPECTION',
        null
      )
    );

    setStormWaterObservations(newStormWaterObservations);
  };

  const showStormWaterSection = Array.from(inspectiontypes.values()).find((i) =>
    i.toLocaleLowerCase().includes('storm event')
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <Link to="/home">
            <QuickSWPPPLogo height="50px" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1">
          <Link to="/home">&lt; Back</Link>
          <br />
        </div>
      </div>

      <hr />
      <div className="margin-top-large"></div>
      {screenNumber === 0 && (
        <>
          <div className="row">
            <div className="col-md-12">
              {/* QSP Form Data */}
              <table
                width={1024}
                className="bordered"
                style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}
              >
                <tbody>
                  <tr>
                    <td>Site Name:</td>
                    <td>
                      <select
                        name="sitename"
                        id="sitename"
                        style={{ width: '400px' }}
                        onChange={(e) => updateSiteName(e.currentTarget.value)}
                      >
                        {projects
                          .sort((a, b) => a.name?.localeCompare(b.name || '') || 0)
                          .map((p) => (
                            <option key={p.name} value={p.name}>
                              {p.name} - Risk Level {p.risklevel_type}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td>QSP Date:</td>
                    <td>
                      <input type="text" value={qspDate} onChange={(e) => setQspDate(e.currentTarget.value)} />
                    </td>
                  </tr>

                  <tr>
                    <td>Inspection Type: </td>
                    <td>
                      {allInspectionTypes.map((i) => {
                        const isChecked = inspectiontypes.has(i);

                        return (
                          <div key={i}>
                            <input
                              type="checkbox"
                              value={i}
                              checked={isChecked}
                              onChange={(e) => toggleInspectionType(i, e.currentTarget.checked)}
                            />
                            {i}
                          </div>
                        );
                      })}
                    </td>
                  </tr>

                  <tr>
                    <td>Weather</td>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td>Storm Begining: </td>
                            <td>
                              <input
                                type="text"
                                value={weather.stormBegining || ''}
                                onChange={(e) => updateWeather(e.currentTarget.value)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Storm Duration (hours):</td>
                            <td>
                              <input
                                type="text"
                                value={weather.stormDuration || ''}
                                onChange={(e) => updateWeather(undefined, e.currentTarget.value)}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Duration since last storm (days): </td>
                            <td>
                              <input
                                type="text"
                                value={weather.timeSinceLastStorm || ''}
                                onChange={(e) => updateWeather(undefined, undefined, e.currentTarget.value)}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Rain Guage Reading (inches): </td>
                            <td>
                              <input
                                type="text"
                                value={weather.rainGuage || ''}
                                onChange={(e) => updateWeather(undefined, undefined, undefined, e.currentTarget.value)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>Exemption Documentation: </td>
                    <td>
                      <textarea
                        style={{ width: '100%' }}
                        name="exception_documentation"
                        value={exemption}
                        onChange={(e) => setExemption(e.currentTarget.value)}
                      ></textarea>
                    </td>
                  </tr>

                  {/* The BMP list */}
                  {allbmps.map((bmp) => (
                    <tr key={bmp.id}>
                      <td colSpan={2}>
                        <BMPSelector
                          key={bmp.id}
                          bmp={bmp}
                          prevBMP={prevQspForm?.bmps.find((b) => b.id === bmp.id)}
                          bmpComment={bmpComments.get(bmp.id) || { has_comment: false, comment: '' }}
                          currentBMPRecommendations={currentBMPs}
                          toggleBMPRecommendation={toggleBMPRecommendation}
                          setPictures={setPictures}
                          setBMPComment={setBMPComment}
                        />
                      </td>
                    </tr>
                  ))}

                  {/* Storm water observations. These are applicable only if pre-storm, storm-event and post-storm are selected */}
                  {showStormWaterSection &&
                    stormwaterobservations.map((so, index) => (
                      <StormWaterObservationInput
                        key={index}
                        site={site}
                        index={index}
                        stormwaterobservation={so}
                        updateStormWaterObservation={updateStormWaterObservation}
                      />
                    ))}
                  {showStormWaterSection && (
                    <tr>
                      <td>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={(e) => addEmptyStormWaterObservation()}
                        >
                          Add New Location
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row justify-content-center" style={{ marginBottom: 20 }}>
            <div className="col-md-3">
              <button type="button" className="btn btn-primary" onClick={genQSPForm}>
                Preview
              </button>
            </div>
          </div>
        </>
      )}

      {screenNumber === 1 && (
        <>
          <div className="row">
            <div className="col-md-12">
              <p>To: {getEmailTo().join(',')}</p>
              <p>Email Subject: {getEmailSubjet()}</p>
              <ContentEditable
                style={{ padding: 5, margin: 5, border: 'black 1px solid' }}
                html={emailBody.current}
                onChange={(e) => (emailBody.current = e.target.value)}
              />
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginBottom: 20 }}>
            <div className="col-md-12" style={{ height: '700px', overflowY: 'scroll', border: 'black 1px solid' }}>
              <Document
                file={`data:application/pdf;base64,${pdffile}`}
                onLoadSuccess={({ numPages }) => setPdfNumPages(numPages)}
              >
                {Array.apply(null, Array(pdfNumPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page key={page} width={1024} pageNumber={page} />
                  ))}
              </Document>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginBottom: 20 }}>
            <div className="col-md-3">
              <button type="button" className="btn btn-info" onClick={() => clearQspForm()}>
                Back
              </button>
            </div>
            <div className="col-md-3">
              <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  if (pdffile) {
                    download(`data:application/pdf;base64,${pdffile}`, 'qsp-form.pdf');
                  }
                }}
              >
                Download PDF
              </button>
            </div>
            <div className="col-md-3">
              <button type="button" className="btn btn-primary" onClick={sendEmail}>
                Send Email!
              </button>
            </div>
          </div>
        </>
      )}

      {screenNumber === 2 && (
        <>
          <div className="row">
            <div className="col-md-12">
              <p>Email Result: {emailResult}</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginBottom: 20 }}>
            <div className="col-md-3">
              <button type="button" className="btn btn-info" onClick={() => clearQspForm()}>
                Back
              </button>
            </div>
            <div className="col-md-3">
              <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  if (pdffile) {
                    download(`data:application/pdf;base64,${pdffile}`, 'qsp-form.pdf');
                  }
                }}
              >
                Download PDF
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(QspScreen);
