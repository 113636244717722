export class Project {
    name?: string;
    wdid?: string;
    zipcode?: string;
    email?: string;
    qsp_name?: string;
    quickswppp_job_number?: string;
    whiteboard_index?: string;
    risklevel_type?: string;
    contact?: string;
    location?: string;
};

export class LoggedInUser {
    displayname?: string;
    email?: string;
    isAdmin?: boolean;
    emailSignature?: string;
}