import dateformat from 'dateformat';
import { LoggedInUser } from './data';

export function addDays(dt: string, count: number): string {
  return subtractDays(dt, -count);
}

export function subtractDays(dt: string, count: number): string {
  const d = stringToDate(dt);
  d.setDate(d.getDate() - count);

  return dateformat(d, 'yyyy-mm-dd');
}

export function stringToDate(dt: string): Date {
  const d = new Date();
  const parts = dt.split('-');

  d.setFullYear(parseInt(parts[0]));
  d.setMonth(parseInt(parts[1]) - 1);
  d.setDate(parseInt(parts[2]));

  return d;
}

export function today(): Date {
  return new Date();
}

export function stringToDateMDY(dt: string): Date {
  const d = new Date();
  const parts = dt.split('-');

  d.setFullYear(parseInt(parts[2]));
  d.setMonth(parseInt(parts[0]) - 1);
  d.setDate(parseInt(parts[1]));

  return d;
}

export function dateToString(d: Date | null): string {
  if (!d) {
    return '';
  }

  // NOTE: JS counts months from 0, so +1
  let month_str = (d.getMonth() + 1).toString();
  if (month_str.length === 1) {
    month_str = '0' + month_str;
  }

  let date_str = d.getDate().toString();
  if (date_str.length === 1) {
    date_str = '0' + date_str;
  }

  return d.getFullYear() + '-' + month_str + '-' + date_str;
}

export function averageOfNonZero(values: (string | null)[]): number | null {
  const filtered_values = values
    .flatMap((so) => (so ? so.split(',') : []))
    .map((v) => parseFloat(v?.replace(/[a-z]*/gi, '').trim() || '0'))
    .filter((v) => v);

  if (filtered_values.length > 0) {
    return filtered_values.reduce((p, c) => p + c) / filtered_values.length;
  } else {
    return null;
  }
}

export interface IResizeImageOptions {
  maxSize: number;
  file: File;
}

export function resizeImage(settings: IResizeImageOptions): Promise<Blob> {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };

  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw Error("Couldn't create Canvas");
    }

    ctx.drawImage(image, 0, 0, width, height);
    let dataUrl = canvas.toDataURL('image/jpeg');
    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}

export function parseProjectEmailString(emails?: string, user?: LoggedInUser): string[] {
  if (!emails) {
    return [''];
  }

  let mimeEmails = emails
    .split(/[ \r\n\t;]+/g)
    .map((token) => {
      if (token.match(/<?[a-zA-Z0-9\.\-]+@[a-zA-Z0-9\.\-]+>?,?/g)) {
        let t = token;
        if (t.endsWith(',')) {
          t = t.slice(0, -1);
        }
        if (t.startsWith('<')) {
          t = t.slice(1, t.length);
        }
        if (t.endsWith('>')) {
          t = t.slice(0, -1);
        }

        return '<' + t.trim() + '>,';
      } else {
        return token;
      }
    })
    .filter((v) => v.trim() !== '')
    .join(' ');

  while (mimeEmails.endsWith(',')) {
    mimeEmails = mimeEmails.slice(0, -1);
  }

  // Also add in the user's email and brad's email
  mimeEmails = mimeEmails + ', Brad Alani<balani@quickswppp.com>';
  if (user) {
    mimeEmails = mimeEmails + `, ${user.displayname}<${user.email}>`;
  }

  return mimeEmails.split(',').map((e) => e.trim());
}
