import React from 'react';

type QSLProps = {
    height: string;
};
export function QuickSWPPPLogo({height}: QSLProps) {
    return (
      <svg height={height} preserveAspectRatio="xMidYMid meet" id="comp-jqlwp1o7svgcontent" data-bbox="0.017 -0.01 199.993 103.492" viewBox="0.017 -0.01 199.993 103.492" xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
      <g>
          <g>
              <path d="M98.5 101.79v-4a6.16 6.16 0 0 0 1.27.87 9.87 9.87 0 0 0 1.56.66 14.2 14.2 0 0 0 1.65.41 8.59 8.59 0 0 0 1.53.15 6.51 6.51 0 0 0 3.62-.81 2.68 2.68 0 0 0 1.19-2.35 2.56 2.56 0 0 0-.4-1.44 4.17 4.17 0 0 0-1.11-1.11 12.1 12.1 0 0 0-1.67-1l-2.07-1c-.79-.42-1.52-.84-2.2-1.28a9.14 9.14 0 0 1-1.78-1.43 6 6 0 0 1-1.18-1.77 5.72 5.72 0 0 1-.43-2.32 5.34 5.34 0 0 1 .74-2.84 6.15 6.15 0 0 1 1.94-2 8.72 8.72 0 0 1 2.75-1.17 13.17 13.17 0 0 1 3.14-.38 12.69 12.69 0 0 1 5.31.82v3.81a8.46 8.46 0 0 0-5.08-1.42 8.92 8.92 0 0 0-1.71.16 5 5 0 0 0-1.52.53 3.31 3.31 0 0 0-1.1 1 2.35 2.35 0 0 0-.42 1.41 2.71 2.71 0 0 0 .32 1.34 3.56 3.56 0 0 0 1 1 9.66 9.66 0 0 0 1.53.91c.59.3 1.29.62 2.08 1A26.44 26.44 0 0 1 109.7 91a10 10 0 0 1 1.89 1.54 6.62 6.62 0 0 1 1.3 1.87 5.44 5.44 0 0 1 .48 2.34 5.87 5.87 0 0 1-.71 3 5.75 5.75 0 0 1-1.92 2 8.62 8.62 0 0 1-2.78 1.11 16.17 16.17 0 0 1-3.32.34 14.14 14.14 0 0 1-1.44-.09c-.56-.06-1.14-.15-1.74-.26s-1.15-.25-1.68-.41a5.36 5.36 0 0 1-1.28-.65z" fill="#1870b9" data-color="1"></path>
              <path d="M145.87 79.49l-6.37 23.23h-4.36l-4.42-16.25a10.5 10.5 0 0 1-.34-2.27h-.07a10.51 10.51 0 0 1-.39 2.24l-4.43 16.28H121l-6.5-23.23h4.23l4.21 17a11.41 11.41 0 0 1 .33 2.23h.08a12.24 12.24 0 0 1 .45-2.23l4.77-17h4L137 96.63a12.75 12.75 0 0 1 .34 2.07h.07a12.14 12.14 0 0 1 .37-2.14l4.13-17.07z" fill="#1870b9" data-color="1"></path>
              <path d="M152.08 94.29v8.43h-3.84V79.49h7a9.36 9.36 0 0 1 6.21 1.86 6.52 6.52 0 0 1 2.2 5.25 7.26 7.26 0 0 1-2.3 5.54 8.73 8.73 0 0 1-6.22 2.15zm0-11.63v8.49h2.5a5.49 5.49 0 0 0 3.77-1.15 4.1 4.1 0 0 0 1.3-3.24q0-4.1-4.76-4.1z" fill="#1870b9" data-color="1"></path>
              <path d="M170.23 94.29v8.43h-3.84V79.49h7.05a9.36 9.36 0 0 1 6.21 1.86 6.52 6.52 0 0 1 2.2 5.25 7.26 7.26 0 0 1-2.3 5.54 8.71 8.71 0 0 1-6.22 2.15zm0-11.63v8.49h2.5A5.49 5.49 0 0 0 176.5 90a4.1 4.1 0 0 0 1.3-3.24q0-4.1-4.76-4.1z" fill="#1870b9" data-color="1"></path>
              <path d="M188.38 94.29v8.43h-3.84V79.49h7.05a9.36 9.36 0 0 1 6.21 1.86 6.49 6.49 0 0 1 2.2 5.25 7.26 7.26 0 0 1-2.3 5.54 8.71 8.71 0 0 1-6.22 2.15zm0-11.63v8.49h2.5a5.49 5.49 0 0 0 3.77-1.15 4.1 4.1 0 0 0 1.3-3.24q0-4.1-4.76-4.1z" fill="#1870b9" data-color="1"></path>
              <path d="M42.78 102.67v-3.82h-.09a7.52 7.52 0 0 1-7.1 4.35q-8 0-8-9.8V80.16h2.82v12.71q0 4 1.44 6a5.14 5.14 0 0 0 4.41 1.93 6 6 0 0 0 4.71-2.14 7.35 7.35 0 0 0 1.35-2.45 9.63 9.63 0 0 0 .46-3.21V80.16h2.81v22.51z" fill="#1870b9" data-color="1"></path>
              <path d="M73.57 101.66a11.81 11.81 0 0 1-6.07 1.56 10.33 10.33 0 0 1-4.35-.83 9.53 9.53 0 0 1-3.3-2.39 10.72 10.72 0 0 1-2.09-3.6 13.73 13.73 0 0 1-.76-4.52 14.31 14.31 0 0 1 .77-4.76 11.27 11.27 0 0 1 2.24-3.89 10.62 10.62 0 0 1 3.6-2.63 12.69 12.69 0 0 1 10 .11v3a9.54 9.54 0 0 0-5.32-1.63 8.14 8.14 0 0 0-3.57.76 7.72 7.72 0 0 0-2.62 2.07 9 9 0 0 0-1.63 3 11.9 11.9 0 0 0-.56 3.71 12.66 12.66 0 0 0 .47 3.45A8.64 8.64 0 0 0 61.86 98a7 7 0 0 0 2.48 2 7.83 7.83 0 0 0 3.51.74 9.38 9.38 0 0 0 5.72-1.89z" fill="#1870b9" data-color="1"></path>
              <path d="M26.22 101.17l-3.63-3.62A12.14 12.14 0 1 0 21 99.66l3.39 3.39zm-14-.24A9.81 9.81 0 1 1 22 91.12a9.81 9.81 0 0 1-9.78 9.81z" fill="#1870b9" data-color="1"></path>
              <path fill="#1870b9" d="M83.73 91.04l10.55-10.88h-3.67l-9.83 10.49h-.08V79.47h-2.82v23.2h2.82V91.7h.08l10.36 10.97h3.98L83.73 91.04z" data-color="1"></path>
              <path fill="#1870b9" d="M53.04 80.16v22.51h-2.81V80.16h2.81z" data-color="1"></path>
              <path d="M133 30.12L104.88 2a6.93 6.93 0 0 0-9.76 0L67 30.12a6.93 6.93 0 0 0 0 9.76L95.12 68a6.93 6.93 0 0 0 9.76 0L133 39.88a6.93 6.93 0 0 0 0-9.76zM96.89 4.4a4.35 4.35 0 0 1 6.14 0l27.49 27.49a4.32 4.32 0 0 1 1 1.59H68.39a4.22 4.22 0 0 1 1-1.59zM78.63 47.26h42.66L113.52 55H86.39zM103 65.52a4.37 4.37 0 0 1-6.14 0L89.35 58h21.21zm21.25-21.22H75.67L69.4 38a4.3 4.3 0 0 1-1-1.59h63.13a4.4 4.4 0 0 1-1 1.59z" fill="#1870b9" data-color="1"></path>
              <path d="M105 20.59l-5-9.76-5 9.76a5.6 5.6 0 1 0 10 0z" fill="#1870b9" data-color="1"></path>
          </g>
      </g>
    </svg>
    );
  }