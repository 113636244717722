export function GetBMPSections(): Map<string, BMPSection> {
    const sectionlist = [];
    sectionlist.push(new BMPSection('1-cm', 'Good Housekeeping for Construction Materials'));
    sectionlist.push(new BMPSection('2-wm', 'Good Housekeeping for Waste Management'));
    sectionlist.push(new BMPSection('3-vsm', 'Good Housekeeping for Vehicle Storage and Maintenance'));
    sectionlist.push(new BMPSection('4-lm', 'Good Housekeeping for Landscape Materials'));
    sectionlist.push(new BMPSection('5-nsm', 'Non-Stormwater Management'));
    sectionlist.push(new BMPSection('6-ec', 'Erosion Controls'));
    sectionlist.push(new BMPSection('7-sc', 'Sediment Controls'));
    sectionlist.push(new BMPSection('8-rorfc', 'Run-On and Run-Off Controls'));

    const bmpsections = new Map<string, BMPSection>();
    sectionlist.forEach((b) => {
        bmpsections.set(b.id, b);
    });

    return bmpsections;
}

export class BMPSection {
    id: string;
    name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class BMPRecommendation {
    id: string;
    name: string;
    recommendedOnDate?: string;
    recommendationText?: string;

    constructor(id: string, name: string, recommendedOnDate?: string, recommendationText?: string) {
        this.id = id;
        this.name = name;
        this.recommendedOnDate = recommendedOnDate;
        this.recommendationText = recommendationText;
    }
}

export class BMPCommentRecommendation {
    has_comment: boolean;
    comment?: string;
    recommendedOnDate?: string;
    recommendationText?: string;

    constructor(has_comment: boolean, comment?: string, recommendedOnDate?: string, recommendationText?: string) {
        this.has_comment = has_comment;
        this.comment = comment;
        this.recommendedOnDate = recommendedOnDate;
        this.recommendationText = recommendationText;
    }
}

export class BMP {
    id: string;
    section: BMPSection;
    name: string;

    recommendations: BMPRecommendation[];
    comment: BMPCommentRecommendation;

    recommendationText?: string;

    picturesBase64?: string[];

    constructor(
        id: string,
        sectionID: string,
        name: string,
        recommendations: BMPRecommendation[],
        comment: BMPCommentRecommendation,
        recommendationText?: string,
        picturesBase64?: string[],
    ) {
        this.id = id;
        const section = GetBMPSections().get(sectionID);
        if (section) {
            this.section = section;
        } else {
            throw new Error('Unknown BMP Section ID:' + sectionID);
        }
        this.name = name;
        this.recommendations = recommendations;

        this.comment = comment;

        this.recommendationText = recommendationText;
        this.picturesBase64 = picturesBase64;
    }
}

export class StormObservationAtLocation {
    location: string;
    ph: string | null;
    ph_observation: string;
    turbidity: string | null;
    turbidity_observation: string;
    comment: string | null;

    constructor(
        location: string,
        ph: string | null,
        ph_observation: string,
        turbidity: string | null,
        turbidity_observation: string,
        comment: string | null,
    ) {
        this.location = location;
        this.ph = ph;
        this.ph_observation = ph_observation;
        this.turbidity = turbidity;
        this.turbidity_observation = turbidity_observation;
        this.comment = comment;
    }
}

export class QspWeatherData {
    stormBegining: string;
    stormDuration: string;
    timeSinceLastStorm: string;
    rainGuage: string;

    constructor(begin?: string, duration?: string, sinceLast?: string, rainGuage?: string) {
        this.stormBegining = begin || '';
        this.stormDuration = duration || '';
        this.timeSinceLastStorm = sinceLast || '';
        this.rainGuage = rainGuage || '';
    }
}

export class QSPFormData {
    datetime: string;
    inspectiontype: string;

    sitename: string;
    risklevel: string;

    photostaken: boolean;

    exemptionreason: string | null;

    weather: QspWeatherData;

    inspectorname: string;

    bmps: BMP[];

    stormobservationsatlocation: StormObservationAtLocation[];

    constructor(
        datetime: string,
        inspectiontype: string,
        sitename: string,
        risklevel: string,
        photostaken: boolean,
        exemptionreason: string,
        weather: QspWeatherData,
        inspectorname: string,
        bmps: BMP[],
        stormobservationsatlocation: StormObservationAtLocation[],
    ) {
        this.datetime = datetime;
        this.inspectiontype = inspectiontype;
        this.sitename = sitename;
        this.risklevel = risklevel;
        this.photostaken = photostaken;
        this.exemptionreason = exemptionreason;
        this.weather = weather;
        this.inspectorname = inspectorname;
        this.bmps = bmps;
        this.stormobservationsatlocation = stormobservationsatlocation;
    }
}

// Class helpful for collecting email sending stuff
export class EmailInfo {
    from: string;
    to: string[];
    subject: string;
    body: string;
    fileName: string;

    constructor(from: string, to: string[], subject: string, body: string, fileName: string) {
        this.from = from;
        this.to = to;
        this.subject = subject;
        this.body = body;
        this.fileName = fileName;
    }
}

// An instance of when a QSP was filed
export class QspCalendarEvent {
    qspId: string;
    reportDate: string;
    timestamp: number;
    projectName: string;
    inspectorName: string;
    inspectionType: string;

    constructor(
        qspId: string,
        reportDate: string,
        timestamp: number,
        projectName: string,
        inspectorName: string,
        inspectionType: string,
    ) {
        this.qspId = qspId;
        this.reportDate = reportDate;
        this.timestamp = timestamp;
        this.projectName = projectName;
        this.inspectionType = inspectionType;
        this.inspectorName = inspectorName;
    }
}

export class QSPUser {
    username: string;
    password: string;
    email: string;
    displayname: string;
    signatureBase64: string;
    emailSignature: string;
    isAdmin: boolean;

    constructor(
        username: string,
        password: string,
        email: string,
        displayname: string,
        signatureBase64: string,
        emailSignature: string,
        isAdmin: boolean,
    ) {
        this.username = username;
        this.password = password;
        this.email = email;
        this.displayname = displayname;
        this.signatureBase64 = signatureBase64;
        this.emailSignature = emailSignature;
        this.isAdmin = isAdmin;
    }
}
