import React, { useEffect, useState } from "react";
import ImageUploader from 'react-images-upload';
import { Link } from "react-router-dom";
import { QSPUser } from "./bmps";
import { QuickSWPPPLogo } from "./components";
import { LoggedInUser } from "./data";
import { resizeImage } from "./utils";

type UserManagementProps = {
    user: LoggedInUser;
}
const UserManagement = ({user}: UserManagementProps) => {
    let [screen, setScreen] = useState(0);
    let [editingUserName, setEditingUsername] = useState<string | null>(null);
    let [editError, setEditError] = useState('');
    let [userFields, setUserFields] = useState<QSPUser | null>(null);
    let [allUsers, setAllUsers] = useState(new Array<QSPUser>());

  const fetchAllUsers = () => {
    fetch('/all_users')
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        // Something is wrong, go to the login screen
        console.log(`Something is very wrong, because status was ${res.status}`);
        console.log(res);
      }
    })
    .then((j) => {
      setAllUsers(j);
    });
  };

  useEffect(() => {
   fetchAllUsers();
  }, [setAllUsers, screen]);

  const startEdit = (username: string) => {
    setScreen(1);

    setEditingUsername(username);
    setUserFields(allUsers.find((u) => u.username === username) || null);
  };

  const startDelete = (username: string) => {
      // Double Confirm
      const con = window.confirm(`Are you sure you want to delete the user ${username}?`);
      if (con) {
          fetch("/delete_user", {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username})
        })
        .then((r) => r.json())
        .then(r => {
            console.log(`Delete user: ${r}`);
            fetchAllUsers();
        });
      }
  };

  const startAdd = () => {
      setScreen(1);

      setEditingUsername(null);
      setUserFields(new QSPUser('', '', '', '', '', '', false));
  };

  const finishEdit = (save: boolean) => {
      // Check if this is a new addition
      if (!editingUserName) {
          // Make sure that the username is unique and non-empty
          if (!userFields?.username) {
              setEditError('Need a non empty username!');
              return;
          }

          if (allUsers.find(u => u.username === userFields?.username)) {
            setEditError(`User ${userFields?.username} already exists. Please use the edit button to change information`);
            return;
          }

          if (!userFields?.password) {
            setEditError('Need a non empty password!');
            return;
          }
      }

      const data = {
          prev: editingUserName,
          data: userFields
      };

      if (save) {
        fetch('/update_user', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
        .then((r) => r.json())
        .then(r => {
            console.log(`Update user: ${r}`);
        });
      } 

      setEditingUsername(null);
      setUserFields(null);
      setScreen(0);
  }
  
  const updateSignature = async (pics: File[]) => {
    const pic = pics[0];
    const blob: any = await resizeImage({file: pic, maxSize: 120});
    const arrayBuffer = await blob.arrayBuffer();
    
    const b64 = btoa(
      new Uint8Array(arrayBuffer)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    
    if (userFields) {
        const newUserField = {...userFields };
        newUserField.signatureBase64 = `data:image/jpeg;base64, ${b64}`;
        setUserFields(newUserField);
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <Link to="/home">
            <QuickSWPPPLogo height="50px"/>
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1">
          <Link to="/home">&lt; Back</Link><br/>
        </div>
      </div>

      <hr/>

      {screen === 0 && 
        <div>
            <div className="row">
                <div className="col-md-2"><strong>Login Name</strong></div>
                <div className="col-md-2"><strong>Name</strong></div>
                <div className="col-md-2"><strong>Email</strong></div>
                <div className="col-md-2"><strong>Signature</strong></div>
                <div className="col-md-2"><strong>Email Signature</strong></div>
                <div className="col-md-2"><strong>Edit</strong></div>
            </div>
            
            {allUsers.map((u, idx) => {
                return (
                <div className="row" key={`${idx}-${u.username}`} style={{paddingBottom: 10}}>
                    <div className="col-md-2">{u.username} {u.isAdmin ? '(Admin)' : ''}</div>
                    <div className="col-md-2">{u.displayname}</div>
                    <div className="col-md-2">{u.email}</div>
                    <div className="col-md-2"><img width={100} src={`${u.signatureBase64}`} /></div>
                    <div className="col-md-2">{u.emailSignature}</div>
                    {u.email !== user.email && 
                        <div className="col-md-2">
                            <button type="button" className="btn btn-info" onClick={(e) => startEdit(u.username)}>Edit</button>
                            <button type="button" className="btn btn-danger" onClick={(e) => startDelete(u.username)}>Delete</button>
                        </div>
                    }
                </div>
                );
            })}

            <hr/>
            <div className="row">
                <div className="col-md-2"><button type="button" className="btn btn-success" onClick={startAdd}>Add</button></div>
            </div>
        </div>
      }

      {screen === 1 && 
        <div>
            <div className="row">
                {editError && 
                    <div className="col-md-4">
                        <div className="alert alert-danger" role="alert">{editError}</div>
                    </div>
                }
            </div>

            <div className="row">
                <div className="col-md-2">Login Name</div>
                <input type="text" value={userFields?.username} onChange={(e) => {
                    if (userFields) {
                        const newUserField = {...userFields }
                        newUserField.username = e.target.value;
                        setUserFields(newUserField);
                    }
                }} />
            </div>

            <div className="row">
                <div className="col-md-2">Password</div>
                <input type="text" value={userFields?.password} onChange={(e) => {
                    if (userFields) {
                        const newUserField = {...userFields }
                        newUserField.password = e.target.value;
                        setUserFields(newUserField);
                    }
                }} />
            </div>
            
            <hr/>

            <div className="row">
                <div className="col-md-2">Email</div>
                <input type="text" value={userFields?.email} onChange={(e) => {
                    if (userFields) {
                        const newUserField = {...userFields }
                        newUserField.email = e.target.value;
                        setUserFields(newUserField);
                    }
                }} />
            </div>

            <div className="row">
                <div className="col-md-2">Inspector's Full Name</div>
                <input type="text" value={userFields?.displayname} onChange={(e) => {
                    if (userFields) {
                        const newUserField = {...userFields }
                        newUserField.displayname = e.target.value;
                        setUserFields(newUserField);
                    }
                }} />
            </div>

            <div className="row">
                <div className="col-md-2">Email Signature</div>
                <textarea value={userFields?.emailSignature} onChange={(e) => {
                    if (userFields) {
                        const newUserField = {...userFields }
                        newUserField.emailSignature = e.target.value;
                        setUserFields(newUserField);
                    }
                }} />
            </div>

            <div className="row">
                <div className="col-md-2">Is Admin</div>
                <input type="checkbox" checked={userFields?.isAdmin || false} value="Is Admin?" onChange={(e) => {
                    if (userFields) {
                        const newUserField = {...userFields }
                        newUserField.isAdmin = e.target.checked;
                        setUserFields(newUserField);
                    }
                }}/>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-2">Physical Signature Image</div>
                {userFields?.signatureBase64 && <img width={100} src={`${userFields?.signatureBase64}`} />}
                <ImageUploader
                    withPreview={false}
                    withIcon={false}
                    label={''}
                    buttonText='Pick Image'
                    singleImage={true}
                    onChange={updateSignature}
                    imgExtension={['.jpg', '.jpeg', '.png']}
                    maxFileSize={5242880}
                />
            </div>

            <div className="row">
                <button type="button" className="btn btn-success" onClick={(e) => finishEdit(true)}>Save</button>
                <button type="button" className="btn btn-secondary" onClick={(e) => finishEdit(false)}>Cancel</button>
            </div>
        </div>
      }
      
      <hr/>
      <div className="margin-top-large"></div>
    </div>
  );
};

export default UserManagement;