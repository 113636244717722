import React, { useState, useEffect } from "react";
import { LoggedInUser, Project } from "./data";
import { ReapFormItem } from "./reapdata";
import download from "downloadjs";
import { Link } from "react-router-dom";
import { QuickSWPPPLogo } from "./components";
import { dateToString, addDays, today, parseProjectEmailString } from "./utils";

type ReapFormItemState = {
  projectname: string,
  isChecked: boolean,
  rainPercent: string,
};

type ReapFormItemRowProps = {
  project?: Project,
  emailstatus: EmailStatus[],
  user?: LoggedInUser,
  rfi?: ReapFormItemState,
  updateReapFormItemState: (name?: string, isChecked?: boolean, rainPercent? : string) => void,
};
const ReapFormItemRow = ({project, user, emailstatus, rfi, updateReapFormItemState}: ReapFormItemRowProps) => {
  const projectEmailStatus = emailstatus.find(s => s.name === rfi?.projectname)?.status;
  
  return (
    <div className="row" style={{marginBottom: 10}}>
      <div className="col-md-4">
        <input type="checkbox" checked={rfi?.isChecked} onChange={(e) => updateReapFormItemState(rfi?.projectname, e.currentTarget.checked)}/>
        &nbsp;
        {rfi?.projectname}
      </div>
      <div className="col-md-3">
        <input type="range" min="50" max="100" step="5" onChange={(e) => updateReapFormItemState(rfi?.projectname, undefined, e.currentTarget.value)}/>
        <span>{rfi?.rainPercent}</span>
      </div>
      <div className="col-md-5">
        {projectEmailStatus && 
          <strong>{projectEmailStatus}</strong>
        }
        {!projectEmailStatus && 
          <span>{parseProjectEmailString(project?.email, user).join(",")}</span>
        }
      </div>
    </div>
  );
};

type EmailStatus = {
  name: string;
  status: string;
};

type ReapScreenProps = {
  projects: Project[];
  user: LoggedInUser;
};

const ReapScreen = ({ user, projects }: ReapScreenProps) => {
  const [reapformitems, setReapformitems] = useState(new Set<ReapFormItem>());
  const [formstate, setFormstate] = useState(new Map<string, ReapFormItemState>());

  const todayStr = dateToString(today());
  const todayplustwo = addDays(todayStr, 2);

  const [reapDate, setReapDate] = useState(todayStr);
  const [rainDate, setRainDate] = useState(todayplustwo);

  const [emailstatus, setEmailStatus] = useState(new Array<EmailStatus>());

  useEffect(() => {
    fetch("/reapforms")
      .then((res) => res.json())
      .then((j: Array<ReapFormItem>) => {
        setReapformitems(new Set(j));

        const newFormState = new Map<string, ReapFormItemState>();

        // Initialize the form state
        for (const rfi of j) {
          const i: ReapFormItemState = {
            projectname: rfi.projectname,
            isChecked: rfi.isNewRainEvent,
            rainPercent: rfi.rainpercent
          };
          newFormState.set(i.projectname, i);
        }

        setFormstate(newFormState);
      });
  }, []);

  const updateReapFormItemState = (name?: string, isChecked?: boolean, rainPercent? : string) => {
    const newFormState = new Map(formstate);

    if (!name) { return }

    const newItem = newFormState.get(name);
    if (newItem) {
      if (typeof(isChecked) !== 'undefined') {
        newItem.isChecked = isChecked;
        if (!newItem.rainPercent) {
          newItem.rainPercent = '50';
        }
      }

      if (typeof(rainPercent) !== 'undefined') {
        newItem.rainPercent = rainPercent;
      }

      newFormState.set(newItem.projectname, newItem);

      console.log(`New state is ${JSON.stringify(Array.from(newFormState))}`);

      setFormstate(newFormState);
    }
  }

  const genReapFormItemsFromCurrent = (): ReapFormItem[] => {
    const selectedReapFormItems: ReapFormItem[] = [];

    Array.from(reapformitems.values())
      .forEach((rfi) => {
        const item_state = formstate.get(rfi.projectname);
        if (item_state) {
          if (item_state.isChecked) {
            const p = projects.find((p) => p.name === rfi.projectname);
            
            rfi.rainpercent = item_state.rainPercent;
            rfi.raindate = rainDate;
            rfi.parsedToEmails = parseProjectEmailString(p?.email);

            selectedReapFormItems.push(rfi);
          }
        }
      });

      return selectedReapFormItems;
  };

  const genPDFs = () => {
    const reapFormItems = genReapFormItemsFromCurrent();
    fetch("/reapformszip", {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(reapFormItems)
    })
    .then((res) => res.blob())
    .then((blob) => download(blob, "reap_forms.zip"));
  };

  const sendEmails = () => {
    const reapFormItems = genReapFormItemsFromCurrent();

    fetch("/reapformssendemails", {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(reapFormItems)
    })
    .then((res) => res.json())
    .then((j) => {
      setEmailStatus(j);
    });
  }

  console.log(`Reap ${reapDate} Rain ${rainDate}`);

  return (
    <div className="container">      
      <div className="row">
        <div className="col-md-3">
          <Link to="/home">
            <QuickSWPPPLogo height="50px"/>
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1">
          <Link to="/home">&lt; Back</Link><br/>
        </div>
      </div>

      <hr/>
      <div className="margin-top-large"></div>
      
      <div className="row" style={{marginTop:20, marginBottom: 10}}>
        <div className="col-md-3">
          <strong>REAP Date:</strong>
        </div>
        <div className="col-md-3">
          <input type="date" value={reapDate} onChange={(e) => setReapDate(e.currentTarget.value)}></input>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <strong>Rain Date:</strong>
        </div>
        <div className="col-md-3">
          <input type="date" value={rainDate} onChange={(e) => setRainDate(e.currentTarget.value)}></input>
        </div>
      </div>

      <div className="row" style={{marginTop:20, marginBottom: 10}}>
        <div className="col-md-4">
          <strong>Project Name</strong>
        </div>
        <div className="col-md-3">
          <strong>Rain Change (Percentage)</strong>
        </div>
        <div className="col-md-3">
          <strong>Emails</strong>
        </div>
      </div>
      {/* Only risk level 2 have QSPs */}
      {Array.from(reapformitems.values())
      .filter((rfi) => {
        const p = projects.find((p) => p.name === rfi.projectname);
        return (p && p.risklevel_type === '2');
      })
      .map((rfi, idx) => {
        const item = formstate?.get(rfi?.projectname);
        if (item) {
          return <ReapFormItemRow key={item.projectname} project={projects.find((p) => p.name === rfi.projectname)} emailstatus={emailstatus} rfi={item} updateReapFormItemState={updateReapFormItemState} />;
        } else {
          return <div key={idx}></div>;
        }
      })
      }

      <div className="row" style={{marginTop:20, marginBottom: 10}}>
        <div className="col-md-3">
          <button type="button" className="btn btn-primary" onClick={genPDFs}>Generate PDFs</button>
        </div>
        <div className="col-md-3">
          <button type="button" className="btn btn-success" onClick={sendEmails}>Send Emails</button>
        </div>
      </div>
    </div>
  );
};

export default ReapScreen;
