import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { QspCalendarEvent } from './bmps';
import { QuickSWPPPLogo } from './components';
import { LoggedInUser, Project } from './data';

type CalendarScreenProps = {
  user: LoggedInUser;
  projects: Project[];
};
const CalendarScreen = ({ user, projects }: CalendarScreenProps) => {
  const [allQsps, setAllQsps] = useState(new Array<QspCalendarEvent>());
  const [displayQsps, setDisplayQsps] = useState(new Array<QspCalendarEvent>());

  const [projectNameFilter, setProjectNameFilter] = useState('');
  const [jobNumFilter, setJobNumFilter] = useState('');
  const [inspectorFilter, setInspectorFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');

  const [reloadCtr, setReloadCtr] = useState(0);
  const [dateRange, setDateRange] = useState('366');

  useEffect(() => {
    fetch(`/all_qsps?dateRange=${dateRange}`)
      .then((res) => res.json())
      .then((qsps: QspCalendarEvent[]) => {
        setAllQsps(qsps);
      });
  }, [reloadCtr, dateRange]);

  useEffect(() => {
    // If the list is specified in the arg, use it, else, fallback to the state
    const q = allQsps
      .slice(0) // clone
      .filter((q) => {
        // If a project Name filter is present, and the string doesn't match, return false
        if (projectNameFilter && !q.projectName.toLocaleLowerCase().includes(projectNameFilter.toLocaleLowerCase())) {
          return false;
        }

        // Job number
        const job_num = projects.find((p) => p.name?.trim() === q.projectName.trim())?.quickswppp_job_number || '';
        if (jobNumFilter && !job_num.toLocaleLowerCase().includes(jobNumFilter.toLocaleLowerCase())) {
          return false;
        }

        // If a inspector filter is present, and the string doesn't match, return false
        if (inspectorFilter && !q.inspectorName.toLocaleLowerCase().includes(inspectorFilter.toLocaleLowerCase())) {
          return false;
        }

        // If a type filter is present, and the string doesn't match, return false
        if (typeFilter && !q.inspectionType.toLocaleLowerCase().includes(typeFilter.toLocaleLowerCase())) {
          return false;
        }

        return true;
      });

    q.sort((a, b) => Date.parse(b.reportDate) - Date.parse(a.reportDate));
    setDisplayQsps(q);
  }, [projectNameFilter, jobNumFilter, inspectorFilter, typeFilter, allQsps, projects]);

  const downloadCsv = () => {
    const csv =
      `"Date", "Project Name", "Inspector Name", "Inspection Type"` +
      displayQsps
        .map((q) => {
          return `"${q.reportDate}", "${q.projectName}", "${q.inspectorName}", "${q.inspectionType}"`;
        })
        .join('\n');

    download(csv, 'QspReport.csv', 'text/csv');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <Link to="/home">
            <QuickSWPPPLogo height="50px" />
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1">
          <Link to="/home">&lt; Back</Link>
          <br />
        </div>
      </div>

      <hr />
      <div className="margin-top-large"></div>

      <div className="row">
      <div className="col-md-2"><strong>Date Range:</strong></div>
        <div className="col-md-3">
          <select onChange={(e) => setDateRange(e.currentTarget.value)}>
            <option value="-1">All</option>
            <option value="7">7 Days</option>
            <option value="31">31 Days</option>
            <option value="366" selected>1 Year</option>
            <option value="732">2 Years</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2"><strong>Filters:</strong></div>
        <div className="col-md-3">
          <input
            type="text"
            placeholder="Project Name"
            value={projectNameFilter}
            onChange={(e) => setProjectNameFilter(e.currentTarget.value)}
          />
        </div>
        <div className="col-md-1">
          <input
            type="text"
            placeholder="Job Num"
            value={jobNumFilter}
            onChange={(e) => setJobNumFilter(e.currentTarget.value)}
          />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            placeholder="Inspector Name"
            value={inspectorFilter}
            onChange={(e) => setInspectorFilter(e.currentTarget.value)}
          />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            placeholder="Type"
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="row margin-top-large">
        <div className="col-md-6">
          Showing {displayQsps.length} of {allQsps.length} records.
          <button className="button btn-info" onClick={downloadCsv}>
            Download CSV
          </button>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-md-2">
          <strong>Date</strong>
        </div>
        <div className="col-md-3">
          <strong>Project Name</strong>
        </div>
        <div className="col-md-1">
          <strong>Job Num</strong>
        </div>
        <div className="col-md-2">
          <strong>Inspector Name</strong>
        </div>
        <div className="col-md-2">
          <strong>Inspection Type</strong>
        </div>
        <div className="col-md-1">
          <strong>Delete</strong>
        </div>
      </div>

      {displayQsps.map((q, idx) => {
        const job_num = projects.find((p) => p.name?.trim() === q.projectName.trim())?.quickswppp_job_number || '';
        return (
          <div className="row" key={`${idx}-${q.reportDate}-${q.projectName}`} style={{ marginBottom: 5 }}>
            <div className="col-md-2">{q.reportDate}</div>
            <div className="col-md-3">{q.projectName}</div>
            <div className="col-md-1">{job_num}</div>
            <div className="col-md-2">{q.inspectorName}</div>
            <div className="col-md-2">{q.inspectionType}</div>
            <div className="col-md-1">
              <button
                className="button btn-danger"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete\n${q.projectName} / ${q.reportDate} by ${q.inspectorName}?\nThis cannot be undone.`
                    )
                  ) {
                    fetch('/delete_qsp_item', {
                      method: 'post',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({qspId: q.qspId}),
                    })
                    .then((res) => res.json())
                    .then((r) => {
                      if (r.result === 'ok') {
                        setReloadCtr(reloadCtr + 1);
                      } else {
                        window.alert(`Deleteing failed. Error was\n${JSON.stringify(r)}`);
                      }
                    })
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        );
      })}

      <hr />
      <div className="margin-top-large"></div>
    </div>
  );
};

export default CalendarScreen;
