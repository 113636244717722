import React, { useState, useEffect } from 'react';
import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCloud, faFile, faSync, faUser } from '@fortawesome/free-solid-svg-icons';

import QspScreen from './qspscreen';
import ReapScreen from './reapscreen';
import LoginScreen from './login';

import { MemoryRouter, Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Project, LoggedInUser } from './data';
import { QuickSWPPPLogo } from './components';
import CalendarScreen from './calendar';
import { QSPUser } from './bmps';
import UserManagement from './usermanagement';

function App() {
  let [projects, setAllProjects] = useState(new Array<Project>());
  let [user, setUser] = useState(new LoggedInUser());

  return (
    <MemoryRouter>
      <div className="App">
        <Switch>
          <Route path="/qsp" render={() => <QspScreen projects={projects} user={user} />} />

          <Route path="/reap" render={() => <ReapScreen projects={projects} user={user} />} />

          <Route path="/calendar" render={() => <CalendarScreen projects={projects} user={user} />} />

          <Route path="/users" render={() => <UserManagement user={user} />} />

          <Route
            path="/syncwhiteboard"
            component={() => {
              //window.location.href = 'http://localhost:3000/doauthorize';
              window.location.href = '/doauthorize';
              return null;
            }}
          />

          <Route path="/home">
            <HomeScreen setAllProjects={setAllProjects} user={user} setUser={setUser} />
          </Route>

          <Route path="/" render={() => <LoginScreen />} />
        </Switch>
      </div>
    </MemoryRouter>
  );
}

interface HomeScreenProps extends RouteComponentProps<any> {
  setAllProjects: (projects: Array<Project>) => void;
  setUser: (user: LoggedInUser) => void;
  user: LoggedInUser;
}
function HomeScreenInner({ setAllProjects, user, setUser, history }: HomeScreenProps) {
  useEffect(() => {
    fetch('/projects')
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          // Something is wrong, go to the login screen
          console.log(res);
          history.push('/');
        }
      })
      .then((j) => {
        setAllProjects(j);
      });
  }, [history, setAllProjects]);

  useEffect(() => {
    fetch('/user_info')
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
          history.push('/');
        }
      })
      .then((j) => setUser(j));
  }, [history, setUser]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2 offset-md-5">
          <QuickSWPPPLogo height="100px" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 offset-md-3">
          <Link className={'home_button'} to="/reap">
            <FontAwesomeIcon icon={faCloud} />
            <br />
            Reaps
          </Link>
        </div>

        <div className="col-md-2">
          <Link className={'home_button'} to="/qsp">
            <FontAwesomeIcon icon={faFile} /> <br />
            QSPs
          </Link>
        </div>
      </div>

      <hr />
      {user.isAdmin && (
        <div className="row">
          <div className="col-md-2 offset-md-3">
            <Link className={'home_button'} to="/calendar">
              <FontAwesomeIcon icon={faCalendar} />
              <br />
              Calendar
            </Link>
          </div>

          <div className="col-md-2">
            <Link className={'home_button'} to="/users">
              <FontAwesomeIcon icon={faUser} />
              <br />
              User Management
            </Link>
          </div>

          <div className="col-md-2">
            <Link className={'home_button'} to="/syncwhiteboard">
              <FontAwesomeIcon icon={faSync} />
              <br />
              Sync Whiteboard
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
const HomeScreen = withRouter(HomeScreenInner);

export default App;
